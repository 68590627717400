.mirrorIcon {
  cursor: pointer;
  border-radius: 10px;
  color: white;
  background-color: #879fcf;
  border: 3px solid white;
  box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.6);
  width: 50px;
  height: 50px;
}

.formContainer-USERS {
  width: 90%;
  max-width: 400px;
  margin: 0vh 2vw 0;
  padding-top: 10vh;
  border-radius: 10px;
  padding: 20px;
}

.formGroup-USERS {
  margin-bottom: 20px;
}

.scoreboard-1 {
  background-color: #879fcf;
  color: white;
  font-weight: 400;
  font-size: 24px;
  width: 55vw;
  height: 6vh;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formLabel-USERS {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.formInput-USERS,
.formTextArea-USERS {
  width: 82vw;
  height: 6vh;
  padding: 8px;
  border-radius: 4px;
  border: 2px solid #484848;
  font-size: 14px;
  color: black;
  box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
  background-color: #ffebd4;
}

#proTip-USERS {
  box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
}

.submitButton-USERS {
  color: white;
  border: 3px solid white;
  border-radius: 30px;
  cursor: pointer;
  font-size: 24px;
  background-color: #879fcf;
  width: 100%;
  height: 7vh;
  margin-left: 3vw;
  box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.6);
}

.formInput-USERS[type="file"]::-webkit-file-upload-button {
  background-color: white;
  color: black;
  padding: 5px 10px;
  border: 1px solid #484848;
  border-radius: 5px;
  cursor: pointer;
  width: 30vw;
  height: 4vh;
  font-size: 16px;
}


.thankyou-USERS {
  margin-top: -6vh;
  text-align: center;
  margin-left: -5vw;
}

@media (max-width: 375px) {
  /* iPhone SE */
  .formContainer-USERS {
    padding-top: 5px;
    margin: 1vh auto 0;
    font-size: 14px;
    transform: scale(0.95);
  }

  .scoreboard-1 {
    height: 7vh;
  }

  .formLabel-USERS {
    font-size: 18px;
  }

  .formInput-USERS,
  .formTextArea-USERS {
    font-size: 12px;
    height: 7vh;
  }

  .submitButton-USERS {
    font-size: 19px;
    height: 9vh;
  }

  .imagePreviewContainer {
    margin-top: 5px;
  }

  .formImagePreview-USERS {
    width: 80px;
    height: 80px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

@media (min-width: 376px) and (max-width: 414px) {
  /* iPhone XR */
  .formContainer-USERS-USERS {
    padding-top: 10px;
    margin: 3vh auto 0;
    font-size: 16px;
  }

  .formLabel-USERS {
    font-size: 20px;
  }

  .formInput-USERS,
  .formTextArea-USERS {
    font-size: 14px;
  }

  .numbers {
    font-size: 20px;
    width: 50px;
    height: 50px;
  }
}

.imagePreviewContainer-USERS {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.formInput-USERS {
  position: relative;
}

.formInput-USERS select {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  background-color: #f4f4f4;
  color: #333;
}

.formImagePreview-USERS {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.imagePreview-USERS {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-top: 10px;
}

.deleteButton {
  cursor: pointer;
  border: none;
  background-color: #ff4d4d; /* red */
  color: white;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.deleteButton:hover {
  background-color: #cc0000; /* darker red */
}

@media (max-width: 375px) {
  /* iPhone SE */
  .deleteButton {
    padding: 3px 6px;
    font-size: 12px;
  }
  .numbers {
    font-size: 16px;
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 376px) and (max-width: 414px) {
  /* iPhone XR */
  .deleteButton {
    padding: 4px 8px;
    font-size: 13px;
  }
}


.suggestion-active,
.suggestions li:hover {
  background-color: #9ff196;
  color: #0f0f0f;
  cursor: pointer;
  font-weight: 250;
  font-size: x-small;
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  margin: 3px;
  border-radius: 5px;
  font-family: sans-serif;
}