.show-password-icon-nav {
  position: absolute;
  top: 29%;
  right: 1vw;
  transform: translateY(-50%);
  cursor: pointer;
  transform: scale(0.8);
}
.forgot-pw {
    text-align: end;
    text-decoration: underline;
    margin-top: -0.5vh;
    font-weight: 200;
}
@media screen and (min-width: 431px) {
  .desktop-container {
    display: block;
  }
  .mobile-container {
    display: none;
  }
  .signin-page,
  .feedback-page,
  .placeview-page {
    background-color: white;
  }
}

@media screen and (max-width: 430px) {
  .desktop-container {
    display: none;
  }
  .mobile-container {
    display: block;
  }
  .signin-page {
    background-color: white;
  }

  .signin-container {
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
  }

  .top-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .logo-container {
    margin-right: 10px;
  }

  .signin-heading {
    margin: 0;
    font-weight: 400;
  }

  .tagline {
    font-size: 14px;
    margin: 5px 0 0 30px;
  }

  .input-field-nav {
    width: 100%;
    height: 4vh;
    padding: 8px;
    margin-bottom: 1vh;
    margin-top: 1vh;
    border: 2px solid #484848;
    border-radius: 5px;
    outline: none;
    background-color: #ffebd4;
    box-shadow: -3px -3px 3px 3px #ffebd4, -3px -3px 5px 2px white inset;
  }

  .signin-page .input-field-nav:focus {
    outline: none;
    box-shadow: 0 0 0 1px black;
  }

  .signin-button-nav {
    width: 95%;
    height: 5vh;
    background-color: #879fcf;
    color: white;
    border: 3px white solid;
    border-radius: 30px;
    font-size: 1.2rem;
    cursor: pointer;
    box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.6);
    margin-left: 3vw;
  }

  .signin-button.loading {
    cursor: not-allowed;
  }

  .error-message {
    color: rgb(209, 60, 60);
    font-size: 14px;
    margin-top: 10px;
  }

  .sign-span-nav {
    margin: 0;
    margin-bottom: 10px;
  }

  p .sign-span-nav {
    color: #879fcf;
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
  }

  button:disabled {
    background-color: #999999;
    cursor: not-allowed;
  }

  .form-label-nav {
    font-size: 1rem;
  }
  .logopic {
    width: 32vw;
    height: 15vh;
    margin-bottom: -3vh;
  }
  .p-font-nav {
    font-size: 1rem;
    font-weight: 300;
    margin-left: 5vw;
  }
}

@media screen and (max-width: 375px) {
  .form-label-nav {
    font-size: 17px;
  }

  .logopic {
    width: 33vw;
    height: 18vh;
  }
  .p-font {
    font-size: 16px;
    margin-top: 12vh;
    margin-left: 12vw;
  }
  p .sign-span-nav {
    font-size: 16px;
  }

  .signin-button {
    height: 8vh;
  }
  .top-container {
    margin-bottom: 10px;
  }
}
