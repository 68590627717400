.container {
    display: flex;
    align-items: center;
  }
  .center-text {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    padding: 0 10px;
  }
  .left-container {
    border: 2px solid #484848;
    border-radius: 5px;
    background-color: #ffebd4;
    box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-weight: 600;
    width: 35vw;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .right-container {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-left: 10px;
  }
  .right-text {
    margin: 0; /* Remove default margin */
    text-align: center; /* Center the text */
  }
  