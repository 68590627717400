/* General styling for EventPlannerHook */
.event-planner-hook {
    font-family: 'Arial', sans-serif; /* or any other font you prefer */
    background-color: #282c34; /* Dark background for the whole page */
    color: white; /* Light text color for better contrast */
    margin: 0;
    padding: 0;
}

/* Styling for the main container */
.event-planner-hook .hook-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
}

/* Styling for headers */
.event-planner-hook h1, 
.event-planner-hook h2 {
    margin: 20px 0;
    font-size: 2em; /* Larger font size for headings */
}

/* Smaller heading for sub-questions */
.event-planner-hook h2 {
    font-size: 1.5em;
}

/* Styling for text inputs and selects */
.event-planner-hook input[type='text'], 
.event-planner-hook input[type='number'], 
.event-planner-hook input[type='date'], 
.event-planner-hook select {
    width: 90%; /* Adjust width as needed */
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    color: #333;
}

/* Styling for checkboxes */
.event-planner-hook input[type='checkbox'] {
    margin: 10px;
    width: 20px; /* Larger checkbox */
    height: 20px; /* Larger checkbox */
}

/* Styling for buttons */
.event-planner-hook button {
    background-color: #4CAF50; /* Green background */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px; /* Smaller padding for a more compact appearance */
    font-size: 0.8em; /* Slightly smaller font size */
    cursor: pointer;
    margin: 5px;
}

/* Hover effect for buttons */
.event-planner-hook button:hover {
    background-color: #367c39; /* Darker shade of green */
}

/* Active button styling */
.event-planner-hook button.active {
    background-color: #ff6347; /* Tomato color for active buttons */
}

/* Labels for better readability */
.event-planner-hook label {
    font-size: 1em;
    margin: 10px 0;
}
