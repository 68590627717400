.time {
  font-size: 14px;
  margin-top: 1px;
}

.EQ {
  margin-bottom: 20px;
}

.event-name {
  font-weight: 400;
  font-size: 18px;
}

.event-place {
  font-weight: 200;
  font-size: 15px;
}

.price-type {
  font-size: 1rem;
  font-weight: 400;
  padding-top: 0.7vh;
  margin-left: 5vw;
}

.list-item-btn {
  width: 90vw;
  height: 14vh;
}

.event-date {
  font-size: 19px;
  font-weight: 300;
}

.top-options-E {
  width: 90%;
  margin: -1vh auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: scale(0.98);
}

.div-containers {
  margin: 0;
  margin-top: 5%;
}


@media (max-width: 375px) {
  .time {
    font-size: 13px;
  }
  .event-name {
    font-size: 15px;
  }
  .event-place {
    font-size: 13px;
  }
  .price-type {
    font-size: 15px;
  }
}

@media (max-width: 375px) {
  .list-item-btn {
    height: 14vh;
  }
}

/* Styling for the event container */
.event-container-EQ {
  transform: scale(0.95);
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

/* Styling for the left column */
.left-column {
  display: flex;
  flex-direction: column;
  margin-top: 0.5vh;
}

/* Styling for the date header */
.date-header {
  margin-bottom: 4vh;
  margin-left: -5vw;
  font-weight: 400;
}

/* Styling for the event details row */
.event-details-row {
  display: flex;
  flex-direction: row;
}

/* Styling for the event icon */
.event-icon {
  margin-top: 10px;
  margin-right: 6px;
  margin-left: -5px;
  color: #eb6660;
}

/* Styling for the event name */
.event-name-EQ {
  font-size: 15px;
  font-weight: 300;
}

/* Styling for the middle column (quest mode) */
.middle-column {
  margin-right: 45vw;
  font-size: 1.2rem;
  font-weight: 500;
}

/* Styling for the right column */
.right-column {
  display: flex;
  flex-direction: column;
  margin-left: -42vw;
}

/* Styling for the price */
.price-EQ {
  margin-bottom: 2vh;
  margin-top: 5vh;
  font-weight: 400;
}

/* Styling for the time container */
.time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -30px;
  margin-bottom: 2vh;
}

/* Styling for the start time */
.start-time-EQ {
  display: flex;
  flex-direction: row;
  margin-top: 7vh;
  margin-left: -3.5vh;
}

.start-time {
  font-weight: 500;
}
/* Styling for the time icon */
.time-icon {
  transform: scale(0.9);
  margin-top: 14px;
  color: #81a0d3;
  margin-right: 5px;
}

/* Styling for the event duration */
.event-duration-EQ {
  margin-top: -2.5vh;
  font-size: 14px;
  font-weight: 200;
}

@media screen and (min-width: 390px) and (max-width: 393px) {
  .event-container-EQ {
    transform: scale(0.9);
    margin-right: 5vw;
  }
}

@media screen and (width:280px) {
  .event-container-EQ {
    transform: scale(0.72);
    margin-right: 27vw;
  }
  .top-options-E {
    transform: scale(0.9);
  }
  .div-containers {
    margin-left: -2vw;
  }
}

@media screen and (min-width:350px) and (max-width: 375px) {
  .event-container-EQ {
    transform: scale(0.87);
    margin-right: 20px;
  }

  .price-EQ {
    margin-top: 7vh;
  }
  .EQ {
    margin-top: -2vh;
  }
}

.suggestion-active,
.suggestions li:hover {
  background-color: #9ff196;
  color: #0f0f0f;
  cursor: pointer;
  font-weight: 250;
  font-size: x-small;
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  margin: 3px;
  border-radius: 5px;
  font-family: sans-serif;
}