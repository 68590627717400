@media screen and (min-width: 431px) {
  .desktop-container {
    display: block;
  }
  .mobile-container {
    display: none;
  }
  .signin-page,
  .feedback-page,
  .placeview-page {
    background-color: white;
  }
}

@media screen and (max-width: 430px) {
  .desktop-container {
    display: none;
  }
  .mobile-container {
    display: block;
  }
  .signin-page {
    background-color: white;
  }

  .signin-container {
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
  }

  .top-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .logo-container {
    margin-right: 10px;
  }

  .signin-heading {
    margin: 0;
    font-weight: 400;
  }

  .tagline {
    font-size: 14px;
    margin: 5px 0 0 30px;
  }

  .input-field {
    width: 100%;
    height: 40px;
    padding: 8px;
    margin-bottom: 30px;
    margin-bottom: 10px;
    border: 2px solid #484848;
    border-radius: 5px;
    outline: none;
    height: 5.5vh;
  }

  .signin-page .input-field:focus {
    outline: none;
    box-shadow: 0 0 0 1px black;
  }

  .signin-button {
    width: 100%;
    height: 7vh;
    background-color: #879fcf;
    color: white;
    border: 3px white solid;
    border-radius: 30px;
    font-size: 22px;
    cursor: pointer;
    box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.6);
  }

  .signin-button.loading {
    cursor: not-allowed;
  }

  .error-message {
    color: rgb(209, 60, 60);
    font-size: 14px;
    margin-top: 10px;
  }

  .sign-span {
    margin: 0;
    margin-bottom: 10px;
  }

  p .sign-span {
    color: #879fcf;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
  }

  button:disabled {
    background-color: #999999;
    cursor: not-allowed;
  }

  .form-label {
    font-size: 19px;
  }
  .logopic {
    width: 32vw;
    height: 15vh;
    margin-bottom: -3vh;
  }
  .p-font {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 300;
    margin-top: 20vh;
    margin-left: 15vw;
  }
}

@media screen and (max-width: 375px) {
  .form-label {
    font-size: 17px;
  }

  .logopic {
    width: 33vw;
    height: 18vh;
  }
  .p-font {
    font-size: 16px;
    margin-top: 12vh;
    margin-left: 12vw;
  }
  p .sign-span {
    font-size: 16px;
  }

  .signin-button {
    height: 8vh;
  }
  .top-container {
    margin-bottom: 10px;
  }
}
