.user-page {
  transform: scale(0.95);
  margin-top: -3vh;
}

.left-container {
  border: 2px solid #484848;
  border-radius: 5px;
  background-color: #ffebd4;
  box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-weight: 600;
  width: 35vw;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-container p {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.interest p {
  margin-left: 4vw;
}

.right-container {
  text-align: start;
}

.column-divide {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-count: 3;
  column-gap: 2vw;
  width: 95%;
  margin-left: 3vw;
}

.bottom-holder {
  display: flex;
  flex-direction: column;
}

.bottom-holder .column-divide {
  margin-bottom: 20px;
  margin-top: 30px;
}

.bottom-holder .left-container {
  flex: 1.5;
  padding-right: 3vw;
}

.bottom-holder .right-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fav-place,
.interest {
  margin-bottom: 10px;
  text-align: center;
}

.edit-button {
  border: none;
  background-color: white;
  box-shadow: -1px 3px 2px 2px rgba(220, 154, 87, 0.4);
  border-radius: 8px;
  width: 18vw;
  height: 5vh;
  margin-top: 2vh;
  font-size: 1.1rem;
  color: black;
}

.edit-name {
  margin-top: -1vh;
}

.modal-USER {
  top: -510px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: white;
  width: 85vw;
  height: 40vh;
  max-width: 600px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 3px white solid;
  background-color: #fff5e9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.update-privacy {
  width: 55vw;
  height: 12vw;
  box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.6);
  border: 3px solid white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  background-color: rgb(235, 102, 96);
  font-size: 1.4rem;
}

input[type="radio"] {
  -webkit-appearance: none; /* this disables default appearance */
  appearance: none;
  width: 20px;
  height: 20px;
  box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.6);
  border: 3px solid white;
  border-radius: 50%;
  outline: none;
}

input[type="radio"]:checked {
  background-color: #96d381;
}

.input-name::placeholder,
.input-name {
  text-align: center;
}

.input-name {
  border: none;
  background-color: #fdfdfd;
  color: black;
  padding: 8px;
  font-weight: 500;
  font-size: 1.7rem;
  margin-top: -1.5vh;
}
.input-name.editing {
  background-color: #fdf5ea;
  border-radius: 10px;
}

.icons-USER {
  position: absolute;
  top: 670px;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  background-color: #fdfdfd;
  border: none;
  border-radius: 50%;
  /* box-shadow: 0px 3px 3px 1px rgba(198, 126, 40, 0.5); */
  cursor: pointer;
  outline: none;
  color: black;
}

.place-name-images {
  font-size: 1.3rem;
}

.column-divide2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-count: 2;
  column-gap: 2vw;
  width: 95%;
  margin-left: 3vw;
}

@media screen and (width: 375px) {
  .list-places {
    font-size: 1.1rem;
    margin-left: 2vw;
  }
  .user-page {
    transform: scale(0.87);
    margin-top: -8vh;
  }
  .modal-USER {
    height: 50vh;
    top: -450px;
  }
  .close-icon {
    transform: scale(1.2);
  }
  .update-privacy {
    transform: scale(0.95);
  }
  .icons-USER {
    top: 549px;
  }
}

@media screen and (min-width: 360px) and (max-width: 390px) {
  .user-page {
    transform: scale(0.87);
    margin-top: -11vh;
  }
  .icons-USER {
    top: 650px;
  }
}

@media screen and (width: 280px) {
  .email-USER,
  .total-points,
  .left-container,
  .redeem-btn,
  .list-places,
  .right-container {
    font-size: 0.9rem;
  }

  .icons-USER {
    top: 465px;
    transform: scale(0.8);
  }
  .edit-name {
    margin-left: 2vw;
    margin-top: 0;
  }
  .input-name {
    width: 80%;
    font-size: 1.3rem;
  }
  .place-name-images {
    font-size: 0.9rem;
  }
}
