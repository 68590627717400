.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Adjust as needed */
  max-width: 80%; /* Adjust as needed */
  margin: 0 auto;
 }
 .form-container b {
  margin-left: 11vw;
  align-self: center;
  font-size:  6vh;
  font-family: 'Inter';
  font-stretch: ultra-expanded;
  padding-top:  0vh;
  line-height: 7vh; /* Adjust this value as needed */
  word-spacing: 0.1vw;
  color: #232323; /* Fill color */
}
.form-container p {
  font-size:  1.4rem;
  font-family: 'Inter';
  font-stretch: ultra-expanded;
  margin-left: 11vw;
}
.invisible-button {
  background-color: transparent;
  position: relative;
  border: none;
  cursor: pointer;
  width: 20vw; /* Adjust button size */
  height: 10vh; /* Adjust button size */
  top: 26vh;
  left: 7vw;
}
.invisible-button2 {
  background-color: transparent;
  position: relative;
  border: none;
  cursor: pointer;
  width: 20vw; /* Adjust button size */
  height: 10vh; /* Adjust button size */
  top: 26vh;
  left: 14vw;
}
.invisible-button3 {
  background-color: transparent;
  position: relative;
  border: none;
  cursor: pointer;
  width: 20vw; /* Adjust button size */
  height: 16vh; /* Adjust button size */
  top: 28vh;
  left: 14vw;
}
.home-page {
    background-color: #f5f9ff;
  }
  
  .contact-us-container {
    display: flex;
    margin-left:  88vw;
  }
  
  .contact-us-image {
    width:  7vw;
    height: auto;
  }
  
  .app-container {
    display: flex;
    margin-left:  10vw;
    margin-top:  1vh;
  }
  
  .app-image {
    width:  30vw;
    margin-right:  10vw;
  }
  
  .app-content {
    background-color: #fdfdfd;
    width:  40vw;
    margin-left:  1vw;
    margin-bottom:  20px;
    border-radius:  10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding:  10px;
    box-shadow:  0px  4px  3px  1px rgba(220,  154,  87,  0.4);
    cursor: pointer;
    padding:  3vw;
  }
  .app-content p {
    font-size:  1.4rem;
    font-family: 'Inter';
    font-stretch: ultra-expanded;
    padding-top:  2vh;
  }
  .app-content b {
    margin-bottom: 0;
    font-size:  8vh;
    font-family: 'Inter';
    font-stretch: ultra-expanded;
    padding-top:  0vh;
    line-height: 7vh; /* Adjust this value as needed */
    word-spacing: 0.3vw;
    color: #232323; /* Fill color */
    text-shadow: -0.1vw 0 0.1vw black; /* Stroke effect */
  }

  
  .app-content input {
    border-radius:  10px;
    width:  20vw;
    height:  2vw;
    padding-left:  10px;
  }
  
  .work-in-progress-container {
    display: flex;
    margin-left:  5vw;
    margin-top:  10vh;
  }
  
  .work-in-progress-content {
    background-color: #fdfdfd;
    width:  35vw;
    margin-left:  1vw;
    margin-bottom:  20px;
    border-radius:  10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding:  10px;
    box-shadow:  0px  4px  3px  1px rgba(220,  154,  87,  0.4);
    cursor: pointer;
    padding:  3vw;
  }
  
  .work-in-progress-content p {
    font-size:  1.4rem;
    font-family: 'Inter';
    font-stretch: ultra-expanded;
    padding-top:  2vh;
  }
  .work-in-progress-content b {
    margin-bottom: 0;
    font-size:  6vh;
    font-family: 'Inter';
    font-stretch: ultra-expanded;
    padding-top:  0vh;
    line-height: 7vh; /* Adjust this value as needed */
    word-spacing: 0.1vw;
    color: #232323; /* Fill color */
  }

  
  .gemqr-image {
    width:  30vw;
    height: 50vh;
    margin-left:  10vw;
  }
  
  .city-container {
    display: flex;
    margin-left:  10vw;
    margin-top:  10vh;
  }
  
  .city-image {
    width:  30vw;
    height: 75vh;
    margin-right:  10vw;
  }
  
  .city-content {
    background-color: #fdfdfd;
    width:  35vw;
    margin-left:  1vw;
    margin-bottom:  20px;
    border-radius:  10px;
    display: flex;
    
    flex-direction: column;
    padding:  10px;
    box-shadow:  0px  4px  3px  1px rgba(220,  154,  87,  0.4);
    cursor: pointer;
    padding:  3vw;
  }
  
  .city-content p {
    font-size:  1.4rem;
    font-family: 'Inter';
    font-stretch: ultra-expanded;
    padding-top:  2vh;
  }
  .city-content b {
    margin-bottom: 0;
    font-size:  6vh;
    font-family: 'Inter';
    font-stretch: ultra-expanded;
    padding-top:  0vh;
    line-height: 7vh; /* Adjust this value as needed */
    word-spacing: 0.1vw;
    color: #232323; /* Fill color */
  }
  
  .join-events-container {
    display: flex;
    margin-left:  5vw;
    margin-top:  10vh;
  }
  
  .join-events-content {
    background-color: #fdfdfd;
    width:  35vw;
    margin-left:  1vw;
    margin-bottom:  20px;
    border-radius:  10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding:  10px;
    box-shadow:  0px  4px  3px  1px rgba(220,  154,  87,  0.4);
    cursor: pointer;
    padding:  3vw;
  }
  
  
  .join-events-content p {
    font-size:  1.4rem;
    font-family: 'Inter';
    font-stretch: ultra-expanded;
    padding-top:  2vh;
  }
  .join-events-content b {
    margin-bottom: 0;
    font-size:  6vh;
    font-family: 'Inter';
    font-stretch: ultra-expanded;
    padding-top:  0vh;
    line-height: 7vh; /* Adjust this value as needed */
    word-spacing: 0vw;
    color: #232323; /* Fill color */
  }
  
  .join-events-image {
    margin-top:  5vh;
  }
  
  .map-image {
    width:  50vw;
    height: 90vh;
    margin-left:  10vw;
  }
  
  .community-container {
    margin-left:  5vw;
  }
  
  .community-image {
    margin-left:  5vw;
    width:  90vw;
  }
  
  .our-community-title {
    font-size:  4rem;
    font-family: 'Inter';
    font-weight:  700;
    text-align: center;
    margin:  1vw  0vw  1vw  10vw;
  }
  
  .table-container {
    width:  100%;
    background-color: #f1f0ee;
  }
  
  .table-container img {
    margin-left:  5vw;
    width:  90vw;
  }