.total-points-OTHERUSER {
  font-size: 1.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 75vw;
  height: 5vh;
  border: 2px solid #484848;
  border-radius: 5px;
  background-color: #ffebd4;
  box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
  margin-bottom: 2vh;
  font-weight: 600;
}

.icons-OTHERUSER {
  position: absolute;
  top: 615px;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  background-color: #fdfdfd;
  border: none;
  border-radius: 50%;
  /* box-shadow: 0px 3px 3px 1px rgba(198, 126, 40, 0.5); */
  cursor: pointer;
  outline: none;
  color: black;
}

.disable-add-friend {
  pointer-events: none;
  opacity: 0.4;
}

@media screen and (width: 390px) {
  .icons-OTHERUSER {
    top: 580px;
  }
}

@media screen and (width: 375px) {
  .icons-OTHERUSER {
    top: 545px;
  }
}
