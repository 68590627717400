.transportation {
  background-color: rgb(135, 159, 207);
  box-shadow: rgba(186, 128, 33, 0.4) 0px 6px 4px,
    rgba(0, 0, 0, 0.2) 0px -5px 0px inset;
  border-radius: 10px;
  color: white;
  border: white solid 2px;
  width: 80px;
  height: 35px;
}

.transportation-gray {
  background-color: rgb(200, 200, 200);
  box-shadow: rgba(186, 128, 33, 0.4) 0px 6px 4px,
    rgba(0, 0, 0, 0.2) 0px -5px 0px inset;
  border-radius: 10px;
  color: white;
  border: white solid 2px;
  width: 80px;
  height: 35px;
}

.transportation-blue {
  background-color: rgb(141, 173, 89);
  box-shadow: rgba(186, 128, 33, 0.4) 0px 6px 4px,
    rgba(0, 0, 0, 0.2) 0px -5px 0px inset;
  border-radius: 10px;
  color: white;
  border: white solid 2px;
  width: 80px;
  height: 35px;
}
