
.formContainer-USERS {
    width: 90%;
    max-width: 400px;
    margin: 0vh 2vw 0;
    padding-top: 10vh;
    border-radius: 10px;
    padding: 20px;
  }

  
  .formGroup-USERS {
    margin-bottom: 20px;
  }
  .formBold-USERS {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    color: #333;
  }
  .formLabel-USERS {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 16px;
    color: #333;
  }
  .formButtonUsers{
    width: 3vw;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    border: 2px solid #484848;
    font-size: 14px;
    font-weight: bold;
    color: black;
    box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
    background-color: #ffebd4;
  }
  .formInput-USERS,
  .formTextArea-USERS {
    width: 82vw;
    height: 6vh;
    padding: 8px;
    border-radius: 4px;
    border: 2px solid #484848;
    font-size: 14px;
    color: black;
    box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
    background-color: #ffebd4;
  }
  .formInput-DATE{
    width: 82vw;
    height: 6vh;
    padding: 8px;
    border-radius: 4px;
    border: 2px solid #484848;
    font-size: 14px;
    color: black;
    box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
    background-color: #ffebd4;
  }
  #proTip-USERS {
    box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
  }
  
  .submitButton-USERS {
    color: white;
    border: 3px solid white;
    border-radius: 30px;
    cursor: pointer;
    font-size: 24px;
    background-color: #879fcf;
    width: 100%;
    height: 7vh;
    margin-left: 3vw;
    box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.6);
  }
  
  .formInput-USERS[type="file"]::-webkit-file-upload-button {
    background-color: white;
    color: black;
    padding: 5px 10px;
    border: 1px solid #484848;
    border-radius: 5px;
    cursor: pointer;
    width: 30vw;
    height: 4vh;
    font-size: 16px;
  }
  
  .fullscreen-modal-USERS {
    width: 85vw;
    height: 40vh;
    background-color: #fff5e9;
    border: white solid 3px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    z-index: 9999;
    border-radius: 15px;
    padding-left: 30px;
    padding-top: 30px;
  }
  
  .gem-USERS {
    width: 60vw;
    margin-left: 30px;
    margin-top: -45px;
  }
  
  .h2-modal-USERS {
    text-align: center;
    font-weight: 500;
    font-size: 1.3rem;
    margin-top: -5px;
    margin-left: 85px;
    z-index: 10;
    width: 30vw;
  }
  .navigate-btn {
    background-color: rgb(235, 102, 96);
    width: 70vw;
    height: 7vh;
    display: block;
    border-radius: 50px;
    overflow: hidden;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
    border: 3px solid white;
    box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.6);
    font-weight: 500;
  }
  
  @media (max-width: 375px) {
    /* iPhone SE */
    .formContainer-USERS {
      padding-top: 5px;
      margin: 1vh auto 0;
      font-size: 14px;
      transform: scale(0.95);
    }
  
    .formLabel-USERS {
      font-size: 18px;
    }
  
    .formInput-USERS,
    .formTextArea-USERS {
      font-size: 12px;
      height: 7vh;
    }
  
    .submitButton-USERS {
      font-size: 19px;
      height: 9vh;
    }
  
    .formImagePreview-USERS {
      width: 80px;
      height: 80px;
      margin-right: 5px;
      margin-bottom: 5px;
    }
    .h2-modal-USERS {
      font-size: 1.2rem;
      margin-top: -2vh;
    }
    .gem-USERS {
      margin-top: -53px;
    }
    .thankyou-USERS {
      width: 95%;
      margin-left: 10px;
      margin-top: -9vh;
    }
    .fullscreen-modal-USERS {
      height: 47vh;
    }
  }
  
  @media (min-width: 376px) and (max-width: 414px) {
    /* iPhone XR */
    .formContainer-USERS-USERS {
      padding-top: 10px;
      margin: 3vh auto 0;
      font-size: 16px;
    }
  
    .formLabel-USERS {
      font-size: 20px;
    }
  
    .formInput-USERS,
    .formTextArea-USERS {
      font-size: 14px;
    }
  }
  
  .imagePreviewContainer-USERS {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .formInput-USERS {
    position: relative;
  }
  
  .formInput-USERS select {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
    background-color: #f4f4f4;
    color: #333;
  }
  
  .formImagePreview-USERS {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .imagePreview-USERS {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  @media (width: 280px) {
    .h2-modal-USERS {
      font-size: 1rem;
      margin-top: -2vh;
      margin-left: 13vw;
    }
    .gem-USERS {
      margin-top: -42px;
      margin-left: -3px;
    }
    .thankyou-USERS {
      width: 95%;
      margin-left: -14px;
      margin-top: -5vh;
      font-size: 0.8rem;
    }
    .fullscreen-modal-USERS {
      height: 43vh;
    }
    .navigate-btn {
      font-size: 0.8rem;
      margin-left: -4vw;
    }
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #9ff196;
    color: #0f0f0f;
    cursor: pointer;
    font-weight: 250;
    font-size: x-small;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 5px 10px 5px 10px;
    margin: 3px;
    border-radius: 5px;
    font-family: sans-serif;
  }
  