@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");

.homepage-body {
  background-color: white;
  font-family: Poppins, sans-serif;
}

.gif-container {
  width: 100%;
  height: 50vh;
  margin: 0;
  margin-top: 20px;
}

.gif {
  width: 100%;
  height: 52vh;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.info h1 {
  margin-top: 3vh;
  font-size: 1.5rem;
  font-weight: 500;
}

.info p {
  font-weight: 300;
  font-size: 1.05rem;
}

.info h1,
.info p {
  margin-bottom: 0;
}

.centered-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  height: 6.5vh;
  width: 73vw;
  border: 3px white solid;
  border-radius: 30px;
  font-size: 1.6rem;
  color: white;
  background: rgb(135, 159, 207);
  box-shadow: rgba(220, 154, 87, 0.6) 0px 4px 3px 1px,
    rgba(0, 0, 0, 0.08) 0px -5px 0px 0px inset;
  cursor: pointer;
}

.button-icon {
  transform: scale(1.8);
  margin-left: 20px;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  border-top: solid rgb(226, 226, 226) 2px;
}

.footer-logo {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 20px;
}

.footer-logo img {
  margin-right: 15px;
}

.footer-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.footer-icons > * {
  flex: 1;
  margin: 0 5px;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.scroll-to-top.show {
  opacity: 1;
  pointer-events: auto;
}

.scroll-to-top::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: rgba(128, 128, 128, 0.3);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.scroll-to-top svg {
  position: relative;
  z-index: 1;
  color: white;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.carousel-container img {
  max-width: 100%;
  height: auto;
}

.carousel-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border-radius: 30px;
  padding-left: 5px;
  cursor: pointer;
}

.left {
  left: 5px;
}

.right {
  right: 5px;
}

.descriptionB {
margin-top: 5vh;
text-align: center;
}

.h2-des {
  margin-top: 0;
  margin-bottom: 3vh;
  font-weight: 500;
  font-size: 1.5rem;
}

 .logo-name {
    font-size: 1.6rem;
  }

@media screen and (width: 390px) {
  .info h1 {
    font-size: 1.6rem;
  }
  .info p {
    font-size: 16px;
  }
  .centered-button {
    margin-top: 35px;
    transform: scale(1);
    font-size: 20px;
  }
  .gif {
    height: 50vh;
  }
  .carousel-container {
    padding: 0 10px;
  }

  .carousel-icon {
    width: 24px;
    height: 24px;
  }
  .h2-des {
    font-size: 24px;
  }
  .description {
    padding: 80px 0;
  }
}

@media screen and (width: 375px) {
  .info h1 {
    font-size: 1.5rem;
    margin-top:1vh;
  }
  .info p {
    font-size: 14px;
  }
  .centered-button {
    margin-top:3vh;
    height: 7vh;
    width: 68vw;
    font-size: 1.4rem;
  }
  .gif {
    height: 50vh;
  }
  .carousel-container {
    padding: 0 10px;
  }

  .carousel-icon {
    width: 24px;
    height: 24px;
  }
  .h2-des {
    font-size: 24px;
  }
  .description {
    padding: 80px 0;
  }
   .logo-name {
    font-size: 1.4rem;
  }
}

@media screen and (width: 280px) {
 .info {
    transform: scale(0.95);
    margin-top: -3vh;
  }
  .centered-button {
    transform: scale(0.95);
    font-size: 1.2rem;
  }

  .info h1 {
    font-size: 1.2rem;
  }

  .info p {
    font-size: 1rem;
  }

  .h2-des {
    font-size: 1.2rem;
  }
  .logo-name {
    font-size: 1.3rem;
  }
}