.event-page {
  margin-top: -4vh;
  transform: scale(0.98);
}
.main-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 20px;
  margin-top: -1vh;
}

.event-name-EP {
  margin-bottom: -15px;
  word-wrap: break-word;
  max-width: 100vw;
  margin-left: 20px;
  text-align: center;
  font-size: 1.85rem;
}

.event-place-EP {
  font-size: 1.6rem;
  font-weight: 300;
  margin-top: 2vh;
}

.event-details-EP {
  width: 90vw;
  max-height: 60vh;
  padding-top: 3vh;
  padding-bottom: 15%;
  text-align: center;
  overflow: hidden;
  font-size: 1.1rem;
  font-weight: 300;
}

.date-time-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 80vw;
  margin-bottom: 2%;
  font-size: 1.1rem;
  margin-top: -3vh;
}

.date-EP-date {
  font-weight: 200;
}

.time-EP-time {
  font-weight: 200;
}

.button-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.button-container button {
  width: 85vw;
  height: 7vh;
  margin-bottom: 15px;
  font-size: 20px;
  color: white;
  border-radius: 50px;
}

.website-button,
.buy-button,
.direction-button {
  color: white;
  border: 3px solid white;
  border-radius: 50px;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  height: 7vh;
  box-shadow: 0px 6px 4px rgba(186, 128, 33, 0.4);
  margin-bottom: 2vh;
  font-size: 1.2rem;
}

.website-button,
.buy-button {
  background-color: #879fcf;
}

.direction-button {
  background-color: #db6e65;
}

.icon-container-EP {
  width: 13vw;
  height: 6.2vh;
}

@media screen and (min-width: 350px) and (max-width: 375px) {
  .main-body {
    margin-top: -3vh;
    transform: scale(0.95);
  }
  .event-name-EP {
    font-size: 1.5rem;
  }
  .event-place-EP {
    font-size: 1.4rem;
  }
  .icon-container-EP {
    width: 12vw;
    height: 7vh;
  }
  .event-details-EP {
    max-height: 55vh;
    padding-top: 1vh;
    font-size: 0.95rem;
  }
  .date-time-container {
    font-size: 0.95rem;
    margin-top: -6vh;
  }
  .button-container button {
    width: 80vw;
    height: 8vh;
    margin-top: 0.4vh;
  }
  .button-container {
    margin-top: 15px;
  }
}

@media screen and (min-width: 390px) and (max-width: 393px) {
  .event-page {
    transform: scale(0.97);
    margin-top: -4vh;
  }
  .main-body {
    margin-top: -1vh;
  }
  .event-name-EP {
    font-size: 1.6rem;
  }
  .event-place-EP {
    font-size: 1.4rem;
  }
}

@media screen and (width: 280px) {
  .event-page {
    transform: scale(0.85);
    margin-top: -12vh;
    margin-left: -10vw;
  }

  .event-name-EP {
    margin-left: 10px;
    font-size: 1.6rem;
  }
  .event-details-EP {
    margin-top: 0vh;
    width: 100vw;
    margin-left: 15vw;
  }

  .date-time-container {
    transform: scale(0.9);
    margin-top: -7vh;
    margin-left: 15vw;
  }
  .button-container {
    transform: scale(0.9);
    margin-top: -2vh;
    margin-left: 15vw;
  }
}
