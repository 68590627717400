.signup-page {
  background-color: white;
}

.signup-container {
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;
}

.top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.signup-heading {
  font-size: 28px;
  margin-bottom: 30px;
}

.tagline {
  font-size: 16px;
  margin-bottom: 10px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 35px;
  margin-top: 8px;
  border: 2px solid #484848;
  border-radius: 5px;
  font-size: 16px;
  background-color: #ffebd4;
 box-shadow: -4px -4px 5px 5px #FFEBD4, 
  -3px -3px 5px 2px white inset;
  height: 6vh;
}
.signup-page .input-field:focus {
  outline: none;
  box-shadow: 0 0 0 1px black;
}

.signup-button {
  width: 100%;
  height: 7vh;
  background-color: #879fcf;
  color: white;
  border: 3px white solid;
  border-radius: 30px;
  font-size: 22px;
  cursor: pointer;
  box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.6);
}

.signup-button.loading {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.forgot-password {
  margin-top: -20px;
  text-align: right;
  font-weight: 300;
  font-size: 18px;
  text-decoration: underline;
}

/* .show-password-button {
  background-color: #ffebd4;
  border: none;
  position: absolute;
  left: 82vw;
  top: 51vh;
} */

.password-field {
  position: relative;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-field {
  padding-right: 40px; 
}

.show-password-icon {
  position: absolute;
  top: 40%;
  right: 4vw; 
  transform: translateY(-50%);
  cursor: pointer;
}


@media screen and (max-width: 375px) {
  .signup-heading {
    font-size: 26px;
  }
  .input-field {
    margin-bottom: 30px;
  }
  .signup-button {
    height: 8vh;
  }
  .forgot-password {
    font-size: 15px;
  }
  .show-password-button {
    transform: scale(0.8);
    left: 80vw;
    top: 63vh;
  }
}

@media screen and (width: 390px) {
  .show-password-button {
    left: 80vw;
    top: 53vh;
  }
}
