.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  z-index: 100;
  background-color: #fdf5ea;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  height: 8vh;
}

.homepage-container {
  padding-top: 50px;
}

.navbar__right {
  display: flex;
  align-items: center;
}

.navbar__links {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  position: absolute;
  top: 100%;
  right: 0;
  width: 101%;
  background-color: #FDFDFD;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navbar.open .navbar__links {
  transform: translateX(0%);
}

.navbar__link {
  margin: 1vh 0;
  padding: 10px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}

.navbar__button {
  margin: 20px 0;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}

.navbar__button:first-of-type {
  margin-right: 10px;
}

.navbar__icon {
  /* transform: scale(1.6); */
}

.users-info {
  font-size: 1rem;
  text-align: start;
  font-weight: 400;
}

.score-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 43vw;
  height: 4vh;
  border: 2px solid #484848;
  border-radius: 5px;
  font-size: 16px;
  background-color: #ffebd4;
  box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.redeem-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 25vw;
  height: 4vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: 3vw;
  border: 2px solid #484848;
  border-radius: 5px;
  font-size: 16px;
  background-color: #96D381;
  box-shadow: -2px -2px 3px 3px #96D381, -3px -3px 5px 2px white inset;
  color: black;
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 15px;
  }

  .navbar__links {
    margin-top: -10px;
  }

  .navbar.open .navbar__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .navbar__link {
    margin: 1vh 0;
    padding: 0;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  .navbar.open .navbar__link {
    opacity: 1;
    transition-delay: 0.2s;
  }

  .navbar.open .navbar__link:nth-child(1) {
    transition-delay: 0.2s;
  }

  .navbar.open .navbar__link:nth-child(2) {
    transition-delay: 0.4s;
  }

  .navbar.open .navbar__link:nth-child(3) {
    transition-delay: 0.6s;
  }

  .navbar.open .navbar__link:nth-child(4) {
    transition-delay: 0.8s;
  }
}

@media screen and (width: 375px) {
  .navbar__icon {
    transform: scale(0.85);
  }
}

@media screen and (width: 280px) {
  .navbar__icon {
    transform: scale(0.8);
  }
  .users-info {
    font-size: 0.8rem;
    margin-left: 10vw;
  }
  .score-navbar {
    font-size: 0.7rem;
  }
  .redeem-navbar {
    font-size: 0.7rem;
  }
}
