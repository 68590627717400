.leader {
  transform: scale(0.9);
}
.fullname {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: -1vh;
}
.points-redeem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  transform: scale(0.95);
  margin-top: -2vh;
}

.leaderboard {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80vw;
  height: 4.5vh;
  border: 2px solid #484848;
  border-radius: 5px;
  background-color: #ffebd4;
  box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
  font-size: 1.25rem;
  font-weight: 500;
  margin-left: 8vw;
  margin-top: 2vh;
}

.top-score-users {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-top: 5vh;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-top: 20px;
  position: relative;
}

.user-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}

.profile-pic {
  width: 11vw;
  height: 5vh;
  border: 2px solid #484848;
  border-radius: 5px;
  background-color: #ffebd4;
  box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
  margin-right: 20px;
}

.name-and-pic {
  display: flex;
  flex: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  margin-left: 20px;
  text-align: left;
}

.user-middle {
  text-align: left;
  font-size: 1rem;
  margin-top: 10px;
  align-self: flex-start;
  margin-left: 20vw;
}

.no-fav {
  display: none;
}

.add-friend {
  margin-top: 20px;
  border: none;
  width: 30vw;
  height: 5vh;
  color: black;
}

.hide-add-friend {
  display: none;
}

.ranking {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f1c57e;
  color: white;
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 1.1rem;
  width: 45px;
  height: 45px;
 display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0%, -99%);
}
