.interests-page {
  transform: scale(0.95);
  margin-top: -2vh;
}
.matrix-board {
  padding-top: 20px;
  display: grid;
  /* set to 3 fix */
  grid-template-columns: repeat(3, 30vw);
  grid-gap: 2% 3%;
  position: relative;
}

.show-hide-button {
  margin-top: 5px;
  text-align: end;
  width: 90vw;
  height: 10vh;
}

.martix-container {
  display: flex;
  justify-content: center;
}
.matrix-box {
  background-color: white;
  box-shadow: -1px 3px 3px 3px rgba(220, 154, 87, 0.4);
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 30px;
  transition: 500ms;
  transition-timing-function: ease;
}
.matrix-box:hover {
  transform: translateY(-4px);
  transition: 500ms;
  transition-timing-function: ease;
}

.name-boxes {
  font-size: 0.85rem;
  margin-top: -4%;
}

.matrix-box svg {
  transform: scale(1.5);
}

.save-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border: 2px solid #484848;
  border-radius: 5px;
  background-color: #ffebd4;
  box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
  width: 30vw;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  color: black;
}
.save-button:active {
  transform: scale(0.95);
}

@media screen and (width: 375px) {
  .matrix-board {
    grid-template-rows: repeat(auto-fill, 15vh);
  }
  .I-email {
    font-size: 1.1rem;
  }
  .save-button,
  .show-hide-button {
    font-size: 1.2rem;
  }
  .interests-page {
    transform: scale(0.88);
    margin-top: -6vh;
  }
}

@media screen and (width: 280px) {
  .I-email,
  .save-button,
  .show-hide-button {
    font-size: 0.9rem;
  }
}
