.vote-button {
  width: 17vw;
  height: 5vh;
  border-radius: 5px;
  border: 2px solid #484848;
  font-size: 1.1rem;
  color: #484848;
  box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
  background-color: #ffebd4;
  text-align: center;
}

.vote-button:active {
  transform: scale(0.9);
}

.countdown-time {
  font-size: 0.9rem;
  font-weight: 300;
}

.placename-voting {
  font-size: 1.25rem;
  color: black;
}

.vote-now {
  border: 2px solid #484848;
  font-size: 1.4rem;
  font-weight: 400;
  color: #484848;
  box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
  background-color: #ffebd4;
  border-radius: 10px;
  width: 62vw;
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 19vw;
}

.vote-now-msg {
  text-align: center;
  font-weight: 300;
  font-size: 1.1rem;
}

.top-option-voting {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0vh;
}

.yes-no-btn {
  width: 35vw;
  height: 6vh;
  color: white;
  border: white 3px solid;
  border-radius: 30px;
  box-shadow: rgba(220, 154, 87, 0.6) 0px 4px 3px 1px,
    rgba(0, 0, 0, 0.08) 0px -5px 0px 0px inset;
  margin-left: 9vw;
  font-size: 1.3rem;
}

.yes {
  background-color: rgb(141, 173, 89);
}

.no {
  background-color: rgb(219, 110, 101);
}

.approveButton {
  cursor: pointer;
  border: none;
  background-color: #2dc555;
  color: white;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.voto-con {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25vh;
}

.voting-closed {
  font-weight: 400;
  font-size: 1.3rem;
}

.yes-no-counts {
  font-weight: 300;
}

@media screen and (min-width: 350px) and (max-width: 375px) {
  .vote-now-msg {
    font-size: 0.98rem;
  }
  .vote-now {
    transform: scale(0.95);
  }
  .countdown-time {
    font-size: 0.85rem;
  }
  .placename-voting {
    font-size: 1.1rem;
  }
  .vote-button {
    transform: scale(0.95);
    font-size: 1.1rem;
  }
  .top-option-voting {
    margin-top: -8vh;
  }

  .yes-no-btn {
    height: 7vh;
    font-size: 1.1rem;
  }
  .voto-con {
    margin-top: 20vh;
  }
  .yes-no-counts {
  font-size: 0.9rem;
}
}

@media screen and (min-width: 390px) and (max-width: 393px) {
  .vote-now-msg {
    font-size: 0.98rem;
  }
  .vote-now {
    transform: scale(0.95);
  }
  .countdown-time {
    font-size: 0.9rem;
  }
  .placename-voting {
    font-size: 1.2rem;
  }
  .vote-button {
    transform: scale(0.95);
    font-size: 1.1rem;
  }
}

@media screen and (width: 280px) {
  .vote-now {
    font-size: 1rem;
  }
  .vote-now-msg {
    font-size: 0.88rem;
  }
  .vote-button {
    font-size: 0.8rem;
    transform: scale(0.9);
  }
  .placename-voting {
    font-size: 0.8rem;
  }
  .countdown-time {
    font-size: 0.6rem;
  }
  .top-option-voting {
    transform: scale(0.9);
    margin-top: -5vh;
  }
  .ic-voting {
    height: 30vh;
  }
  .h1-voting {
    font-size: 1.5rem;
  }
  .h2-voting {
    font-size: 1.1rem;
  }
  .left {
    margin-left: -4vw;
  }
  .right {
    margin-right: -4vw;
  }
  .voto-con {
    transform: scale(0.8);
    margin-top: 29vh;
  }
}
