.verification-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 8vh;
}

.verification-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.verification-input {
  width: 13vw;
  height: 6vh;
  border-radius: 5px;
  border: 2px solid #484848;
  background-color: #ffebd4;
  box-shadow: -4px -4px 5px 5px #FFEBD4, 
  -3px -3px 5px 2px white inset;
  outline: none;
  padding: 5px;
  text-align: center;
  margin: 0 5px;
  font-size: 18px;
  font-weight: 400;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.verification-input:focus {
  border: 1px solid black;
}

.verification-button,
.resend-button {
  color: white;
  border: 3px solid white;
  border-radius: 50px;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  height: 7vh;
  box-shadow: 0px 6px 4px rgba(186, 128, 33, 0.4);
  margin-bottom: 2vh;
  font-size: 1.2rem;
}

.verification-button {
  background-color: #879fcf;
}
.resend-button {
  background-color: #db6e65;
}

.verification-button:disabled,
.resend-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.verification-error {
  color: red;
  margin-top: 10px;
}

.verification-buttons {
  display: flex;
  flex-direction: column;
}

.verification-buttons {
  display: flex;
  justify-content: space-between;
  width: 340px;
  margin-top: 10px;
}

.verification-button {
  margin-right: 10px;
}

.email-span {
  font-weight: 400;
  color: black;
}

.verification-title {
  font-size: 2.5rem;
  text-align: left;
}

.verification-email {
  font-size: 1.15rem;
  text-align: left;
  font-weight: 300;
}

.top-field {
  margin-left: 3vw;
}

@media screen and (width: 375px) {
  .verification-input {
    height: 7vh;
  }

  .verification-title {
    font-size: 2.2rem;
  }
  .verification-button,
  .resend-button {
    height: 9vh;
  }
  .top-field {
    margin-left: 4vw;
  }
  .verification-container {
    margin-top: 3vh;
  }

}
