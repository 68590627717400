.top-div {
  width: 100%;
  height: 3vh;
  border-radius: 10px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 8vh;
}

.bottom-div {
  flex: 2;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 4vw;
}

.scrollable-container {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.scrollable-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scrollable-item {
  width: 94%;
  margin-bottom: 20px;

  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.4);
  cursor: pointer;
}

.scrollable-item.selected {
  background-color: #ffe6c8;
}

.coupon,
.placename {
  font-size: 1.15rem;
  font-weight: 500;
}

.section {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  background-color: lightblue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.middle {
  flex-direction: column;
}

.bottom-div::-webkit-scrollbar {
  width: 8px;
}

.bottom-div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.bottom-div::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.bottom-div::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.bottom-div {
  padding-top: 40px;
}

.scrollable-item.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.total-points {
  font-size: 1.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 55vw;
  height: 5vh;
  border: 2px solid #484848;
  border-radius: 5px;
  background-color: #ffebd4;
  box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-weight: 600;
}

.instruction-pts {
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
  margin-left: 5vw;
}

.redeem-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 25vw;
  height: 5vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: 3vw;
  border: 2px solid #484848;
  color: black;
  border-radius: 5px;
  font-size: 1.15rem;
  background-color: #96d381;
  font-weight: 500;
  box-shadow: -2px -2px 3px 3px #96d381, -3px -3px 5px 2px white inset;
}

.redeem-btn[disabled] {
  background-color: #96d381;
  opacity: 0.6;
  color: black;
}

.fullscreen-modal {
  width: 85vw;
  height: 85vh;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 9999;
  border-radius: 10px;
  padding-left: 30px;
  padding-top: 30px;
}

.h2-modal {
  padding-left: 10%;
}

.fullscreen-modal > div {
  color: #000000;
  /* text-align: center; */
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1;
  transform: scale(1.5);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(167, 167, 167, 0.3);
  z-index: 9999;
}

.values {
  font-size: 0.95rem;
  margin-top: 2px;
}

.available-cp {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -40%);
  background-color: #ffa707;
  color: rgb(0, 0, 0);
  padding: 5px;
  border-radius: 50px;
  font-size: 1.2rem;
  width: 40px;
  height: 40px;
  text-align: center;
}

@media (width: 390px) {
  .total-points {
    font-size: 1.2rem;
  }

  .logo2 {
    height: 100px;
    width: 100px;
  }
  .h2-modal {
    padding-left: 8%;
  }
  .available-cp {
  transform: translate(-30%, -40%);

}
}

@media (width: 280px) {
  .total-points,
  .redeem-btn {
    font-size: 0.9rem;
  }
  .top-options {
    transform: scale(0.85);
  }
}


@media (width: 375px) {
.available-cp {
  transform: translate(-20%, -40%);
  background-color: #ffa707;
  padding: 5px;
  font-size: 1rem;
  width: 35px;
  height: 35px;
}
}

@media (width: 360px) {
.available-cp {
  transform: translate(-20%, -40%);
  background-color: #ffa707;
  padding: 5px;
  font-size: 1rem;
  width: 35px;
  height: 35px;
}
}