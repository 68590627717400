.scrollable-container {
  width: 100%;
  position: relative;
}

.news-frame {
  width: 91vw;
  margin-left: 1vw;
  margin-bottom: 20px;

  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.4);
  cursor: pointer;
}

.newsfeed-TG {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  height: 10vh;
}

.fullname-display {
  font-weight: 500;
  font-size: 1rem;
}

.message-content,
.suggest-location-post {
  max-height: 30vh;
  font-size: 1.1rem;
  margin-top: 5px;
}
.profile-picture {
  background-color: #ffebd4;
  border: 2px solid #484848;
  box-shadow: -2px -2px 3px 2px #ffebd4, inset -3px -3px 5px 1px #fff;
  border-radius: 5px;
  width: 45px;
  height: 45px;
  align-self: flex-start;
}

.submitted-date {
  font-size: 0.8rem;
  color: #707070;
  text-align: center;
}

.add-points {
  font-size: 1rem;
  font-weight: 400;
}

.check-in-join,
.suggest-location-vote {
  background-color: rgb(135, 159, 207);
  color: white;
  border: 3px solid #fff;
  border-radius: 30px;
  box-shadow: 0 4px 3px 1px rgba(220, 154, 87, 0.6),
    inset 0 -5px 0 0 rgba(0, 0, 0, 0.08);
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
}

.placename-checkin {
  color: rgb(135, 159, 207);
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.check-in-message {
  max-height: 10vh;
  margin-top: 5px;
  font-size: 1rem;
}

.message-box-input {
  background-color: #ffebd4;
  border: 2px solid #484848;
  box-shadow: -2px -2px 3px 2px #ffebd4, inset -3px -3px 5px 1px #fff;
  border-radius: 5px;
  padding: 5px;
  width: 70vw;
  margin-right: 20px;
}

.message-box-input::placeholder {
  font-size: 1rem;
  color: rgb(182, 182, 182);
}

.message-box-send {
  background-color: #ffebd4;
  color: black;
  border: 2px solid #484848;
  box-shadow: -2px -2px 3px 2px #ffebd4, inset -3px -3px 5px 1px #fff;
  border-radius: 5px;
  width: 55px;
  height: 55px;
  text-align: start;
  font-size: 1rem;
  font-weight: 400;
  padding-right: 50px;
}

.message-response {
  display: flex;
  flex-direction: space-around;
  align-items: center;
  height: 30px;
  margin-top: 0;
  font-size: 1.1rem;
  color: rgb(135, 159, 207);
  font-weight: 400;
}

.replyingto-message-OTHER {
  text-align: start;
  font-size: 0.9rem;
  color: rgb(135, 159, 207);
  margin-left: 56px;
  font-weight: 400;
}

.replyingto-message-ME {
  text-align: end;
  font-size: 0.9rem;
  color: rgb(135, 159, 207);
  padding-right: 80px;
  font-weight: 400;
}

.new-message-post-content-ME {
  margin-left: -20px;
  width: 75vw;
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right: 20px;
}

.reply-info {
  font-size: 1.1rem;
  background-color: #f8d6af;
  color: rgb(81, 81, 81);
  width: 70vw;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 20px;
  padding-right: 5px;
}

.suggest-location-message {
  margin-top: 5px;
  font-size: 1rem;
}

.uploaded-picture {
  width: 256px;
  height: 256px;
  object-fit: cover;
  border: 2px solid white;
  border-radius: 15px;
  box-shadow: 0 6px 4px rgba(186, 128, 33, 0.4),
    inset 0 -5px 0 0 rgba(255, 255, 255, 0.2);
  background-color: #fdf5ea;
}

.upload-picture-message {
  margin-top: 5px;
  font-size: 1.1rem;
}

.upload-picture-message-ME {
  margin-top: 5px;
  font-size: 1.1rem;
  text-align: end;
  margin-right: 14px;
}

.question-field {
  background-color: #ffebd4;
  border: 2px solid #484848;
  box-shadow: -2px -2px 3px 2px #ffebd4, inset -3px -3px 5px 1px #fff;
  border-radius: 5px;
  margin-left: -5px;
  height: 18vh;
  width: 85vw;
  margin-top: 15px;
}

.question-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 76%;
  margin: 0;
  margin-top: -2px;
}

.question-content p {
  margin: 0px 0;
  font-size: 1rem;
  font-weight: 400;
}

.question-content button {
  margin-top: 5px;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  background-color: rgb(135, 159, 207);
  color: white;
  border-radius: 30px;
  box-shadow: inset 0 -5px 0 0 rgba(0, 0, 0, 0.08);
  width: 120px;
  height: 80px;
  font-weight: 400;
}
.closeicon-nf {
  width: 25px;
  height: 25px;
  background-color: #f24e1e;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}

.message-box {
  margin-top: 30px;
  margin-left: 7px;
  margin-bottom: 50px;
}

.message-box-QOTD {
  margin-top: 30px;
  margin-left: 7px;
  margin-bottom: 50px;
}

.message-box-send.answer {
  width: 16vw;
  padding-right: 60px;
}

@media screen and (width: 375px) {
  .message-box {
    margin-left: 7px;
  }
  .question-field {
    height: 20vh;
  }
  .message-box-send.answer {
    font-size: 0.9rem;
  }
  .uploaded-picture {
    width: 230px;
    height: 230px;
  }
}

@media screen and (width: 360px) {
  .question-field {
    height: 18vh;
  }
  .message-box-send.answer {
    font-size: 0.9rem;
  }
}


.add-point {
	background-color: #e5efe1;
    color: #7daf6b;
    cursor: pointer;
    font-weight: 500;
    font-size: x-small;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 3px 10px 3px 10px;
    margin: 3px;
    border-radius: 5px;
    font-family: sans-serif;
}

.del-point {
	background-color: #fbe0df;
    color: #ef8480;
    cursor: pointer;
    font-weight: 500;
    font-size: x-small;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 3px 10px 3px 10px;
    margin: 3px;
    border-radius: 5px;
    font-family: sans-serif;
}

.city-tag {
	background-color: #e6ecf6;
    color: #81a0d3;
    cursor: pointer;
    font-weight: 600;
    font-size: x-small;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 3px 10px 3px 10px;
    margin: 3px;
    border-radius: 5px;
    font-family: sans-serif;
}