.add-picture-note {
  font-size: 0.7rem;
  position: absolute;
  top: 41%;
  left: 5%;
}
.pending-style {
  pointer-events: none;
  filter: grayscale(100%);
}

.pending-image {
  display: block;
  object-fit: cover;
  position: absolute;
  left: -5px;
  z-index: 100;
  width: 400px;
}

.disabled-placeview {
  opacity: 0.5;
  pointer-events: none;
}

.image-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.my-box {
  width: 95%;
  max-width: 95vw;
  height: 82vh;
  margin: -40px auto 0;
  padding: 2%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow: auto;
}

.directions {
  width: 85vw;
  height: 7vh;
  margin: 25px auto;
  box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.6);
  border: 3px solid white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  background-color: #879fcf;
}

.suggestions {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
}

.top-options {
  width: 90%;
  margin: -1vh auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.points {
  width: 60vw;
  height: 48px;
  text-align: center;
  padding-top: 0.5rem;
  background-color: white;
  border-radius: 5px;
}

.icon {
  cursor: pointer;
  border-radius: 5px;
  transform: scale(2);
}

.arrow,
.green,
.red {
  background-color: white;
}

.green {
  background-color: #8dad59;
}

.red {
  background-color: #e4999e;
}

.time-table {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(253, 245, 234, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.time-table-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 50vh;
  background-color: rgba(253, 245, 234, 0.8);
  padding: 20px;
  text-align: center;
  margin-top: -15vh;
  font-weight: 200;
}

.date-time-tr {
  font-size: 22px;
}

.date-time-tbody {
  font-size: 18px;
}

.my-box img {
  width: 25%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.icons {
  position: absolute;
  top: 38%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  background-color: #fdfdfd;
  border: none;
  border-radius: 50%;
  /* box-shadow: 0px 3px 3px 1px rgba(198, 126, 40, 0.5); */
  cursor: pointer;
  outline: none;
}

.left {
  left: 0;
  margin-left: 2.5vw;
}

.right {
  right: 0;
  margin-right: 2.5vw;
}

.image-con {
  height: 32vh;
  width: 68vw;
}

.h1-tag,
.h2-types {
  margin-top: 1vh;
  height: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1rem;
}

.h1-tag.height-adjust {
  height: 25px;
}

.h1-tag {
  font-weight: 400;
}

.h2-types {
  margin-top: 15px;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 24px;
}

.checkin {
  width: 100%;
  height: 100%;
  background-color: #00000038;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 9999;
  border-radius: 10px;
  padding-left: 30px;
  padding-top: 30px;
}

.checkin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  height: 40vh;
  margin-top: 18vh;
  border-radius: 30px;
  background-color: #fdf5ea;
  border: 3px solid white;
  width: 86vw;
}

.overlaypage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(253, 245, 234, 0.6);
  z-index: 9999;
}

.checkin-content {
  position: relative;
}

.checkin-container {
  position: relative;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 19px;
  cursor: pointer;
  width: 8vw;
  height: 4vh;
  background-color: rgb(235, 102, 96);
  box-shadow: rgba(186, 128, 33, 0.4) 0px 6px 4px,
    rgba(0, 0, 0, 0.2) 0px -4px 0px inset;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
}

.location-check {
  margin-top: 5vh;
  font-size: 1.5rem;
  font-weight: 500;
}

.checkin-btn-PV {
  width: 55%;
  height: 18%;
  box-shadow: 0px 4px 3px 1px rgba(220, 154, 87, 0.6);
  border: 3px solid white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  background-color: rgb(235, 102, 96);
  font-size: 1.4rem;
}

.s-500-points {
  width: 40vw;
  height: 5vh;
  border: #484848 solid 2px;
  border-radius: 10px;
  margin-top: 2vh;
  padding-top: 0.6vh;
  background-color: #ffe6c8;
  box-shadow: -4px -4px 5px 5px #ffe6c8, -3px -3px 5px 2px white inset;
}
.skipdiv {
  position: absolute;
  bottom: 2%;
  right: 5%;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
}

.description-div {
  width: 83vw;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 6vw;
  font-size: 19px;
  font-weight: 300;
}

.checkin-points {
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: -1vh;
}

@media only screen and (max-width: 375px) {
  .page-container {
    transform: scale(0.87);
  }
  .tg-placeview {
    margin-top: -7vh;
  }
  .my-box {
    margin: -5px auto;
  }
  .icons {
    top: 37%;
    transform: scale(0.9);
  }
  .description {
    font-size: 16px;
    padding: 10px 0;
  }
  .suggestions {
    transform: scale(0.8);
  }
  .left {
    left: 0;
    margin-left: 2vw;
  }

  .right {
    right: 0;
    margin-right: 2vw;
  }
  .image-con {
    height: 36vh;
    width: 65vw;
  }
  .h1-tag {
    margin-top: 17px;
    font-size: 30px;
  }
  .h2-types {
    margin-bottom: 10px;
    font-size: 22px;
  }
  .description-div {
    width: 85vw;
    height: 20vh;
  }
  .directions {
    height: 8.5vh;
  }
  .checkin-container {
    font-size: 0.8rem;
    width: 85%;
    margin-left: 5vw;
  }
  .close-icon {
    margin: 10px;
    width: 9vw;
    height: 5vh;
    z-index: 999;
  }
  .location-check,
  .checkin-points {
    font-size: 1.1rem;
  }
  .location-check {
    margin-top: 2vh;
  }
  .skipdiv {
    transform: scale(0.9);
  }
  .checkin-btn-PV {
    transform: scale(0.9);
  }
  .pending-image {
    display: block;
    object-fit: cover;
    position: absolute;
    top: 10px;
    left: -10px;
    z-index: 100;
    width: 350px;
  }
}

@media only screen and (width: 360px) {
  .pending-image {
    display: block;
    object-fit: cover;
    position: absolute;
    left: -15px;
    z-index: 100;
    width: 350px;
  }
}

@media only screen and (min-width: 390px) and (max-width: 393px) {
  .description {
    font-size: 18px;
    padding: 15px 0;
  }
  .location-check,
  .checkin-points {
    font-size: 1.35rem;
  }
  .location-check {
    font-size: 1.35rem;
    margin-top: 1vh;
  }
  .checkin-btn-PV {
    transform: scale(0.95);
  }
}

@media screen and (width: 280px) {
  .image-con {
    height: 25vh;
    width: 68vw;
  }
}
