@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-family: "Jost", sans-serif;
  background-color: #fdfdfd;
}

.SuggestionsBtn {
  color: white;
  border-radius: 50px;
  border: 3px solid white;
  box-shadow: 0px 6px 4px rgba(186, 128, 33, 0.4),
    0px -5px 0px 0px rgba(0, 0, 0, 0.2) inset;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0.15rem;
  height: 8vh;
  width: 35vw;
}

.bluebtn {
  background-color: #879fcf;
}

.yellowbtn {
  background-color: #f1c57e;
}

.redbtn {
  background-color: #db6e65;
}

.greenbtn {
  background-color: #74b9a9;
}

.SuggestionsBtn:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.search {
  transform: scale(1.4);
}

/* scoreboard */
.scoreboard {
  background-color: #879fcf;
  color: white;
  font-weight: 400;
  font-size: 24px;
  width: 55vw;
  height: 6vh;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 5%;
  transform: translate(-50%, -50%);
}

/* modal */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000038;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  width: 85vw;
  height: 40vh;
  max-width: 600px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 3px white solid;
  background-color: #fff5e9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.modal-content {
  text-align: center;
  position: relative;
  margin-top: -3vh;
}

.modal-content button {
  background-color: #ccc;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.info-button {
  position: absolute;
  top: 320px;
  right: 65px;
  z-index: 999;
  cursor: pointer;
}
.rank-button {
  position: absolute;
  top: 320px;
  left: 65px;
  z-index: 999;
  cursor: pointer;
}

.map-button {
  position: absolute;
  top: 320px;
  left: 190px;
  z-index: 999;
  cursor: pointer;
}

.QA-message {
  text-align: center;
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 1vh;
}

.close-button {
  /* position: absolute;
  top: 31vh;
  right: 10vw;
  cursor: pointer; */
  position: absolute; /* position the close button relative to the modal */
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 100000;
}

.modal-content-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.modal-header {
  margin-top: 0.5vh;
}

.modal-instruction {
  font-size: 1rem;
  font-weight: 300;
}

.image-modal {
  width: 20vw;
  height: 9vh;
  margin-top: 3vh;
}

.im-HG {
  width: 25vw;
  height: 11vh;
  margin-top: 1vh;
}

.points-holder {
  border: 2px solid #484848;
  font-size: 14px;
  color: black;
  box-shadow: -4px -4px 5px 5px #ffebd4, -3px -3px 5px 2px white inset;
  background-color: #ffebd4;
  width: 80%;
  height: 4.5vh;
  border-radius: 5px;
  font-size: 1.15rem;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1vh;
}

.bottom-div-holder {
  margin-top: 1vh;
}

.next-div {
  font-size: 1.05rem;
}

.instructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  margin-top: 4vh;
  font-size: 0.9rem;
}

.empty-space {
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

/* suggestions group */
.Suggestions {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
}

.SuggestionsBtn {
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85vw;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: -5vh;
  transform: scale(0.9);
}

.Suggestions {
  overflow: hidden;
  position: fixed;
}

.top-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.homebtn-icon {
  margin-top: -2vh;
}

.map-il {
  transform: scale(0.95);
  margin-left: 10vw;
  margin-top: -3vh;
}

.never-show-again {
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-weight: 400;
}

@media screen and (min-width: 431px) {
  .suggestion-page {
    background-color: #fdf5ea;
  }
}

@media screen and (width: 390px) {
  .empty-space {
    height: 14vh;
  }
  .info-button {
    bottom: 350px;
    right: 50px;
  }
  .map-il {
    transform: scale(0.85);
    margin-top: -5vh;
  }
  .info-button,
  .rank-button {
    top: 282px;
  }
  .QA-message {
    margin-top: -3vh;
  }
  .map-button {
    position: absolute;
    top: 284px;
    left: 183px;
  }
}

@media (width: 360px), (width: 375px) {
  .Suggestions {
    transform: scale(0.95);
  }

  .SuggestionsBtn {
    margin: 8px auto;
    width: 75vw;
    height: 8.5vh;
    font-size: 22px;
  }

  .buttonWrapper {
    margin-top: -30px;
    transform: scale(0.97);
  }

  .scoreboard {
    font-size: 20px;
    width: 50vw;
    height: 6vh;
  }

  .map-il {
    transform: scale(0.8);
    margin-top: -8vh;
  }

  .tg-s {
    margin-top: -2vh;
  }
  .info-button {
    top: 250px;
    right: 55px;
  }
  .rank-button {
    transform: scale(0.9);
    top: 250px;
    left: 55px;
  }
  .modal {
    height: 45vh;
  }
  .close-button {
    transform: scale(0.9);
    top: 10px;
    z-index: 100000;
  }
  .image-modal {
    width: 18vw;
    height: 10vh;
    margin-top: 3vh;
  }
  .points-holder {
    width: 70%;
  }
  .empty-space {
    height: 10vh;
  }
  .im-HG {
    margin-top: -1vh;
  }
  .QA-message {
    margin-top: -4vh;
    font-size: 1.1rem;
  }
  .map-button {
    position: absolute;
    top: 251px;
    left: 162px;
  }
}

@media (max-width: 280px) {
  .top-group {
    transform: scale(0.9);
  }

  .map-il {
    transform: scale(0.7);
    margin-left: -8vw;
    margin-top: -8vh;
  }

  .SuggestionsBtn {
    font-size: 1rem;
    width: 83vw;
    height: 8vh;
  }

  .buttonWrapper {
    margin-top: -10vh;
  }

  .close-button {
    transform: scale(0.8);
    top: 4px;
    z-index: 10000;
  }
  .image-modal {
    transform: scale(0.9);
  }
  .modal-content-holder {
    transform: scale(0.8);
    margin-top: -2vh;
  }
  .next-div {
    margin-top: 2vh;
    transform: scale(0.8);
    margin-left: 150px;
  }
  .empty-space {
    height: 5vh;
  }
  .info-button {
    transform: scale(0.7);
    top: 240px;
    right: 30px;
  }
  .rank-button {
    transform: scale(0.7);
    top: 240px;
    left: 30px;
  }
  .QA-message {
    font-size: 0.8rem;
    margin-top: -7vh;
    margin-bottom: 7vh;
  }
  .bottom-div-holder {
    margin-top: -4vh;
    font-size: 0.7rem;
  }
  .never-show-again {
    display: none;
  }
}
