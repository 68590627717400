/* nav button span {
  display: inline-block;
  padding: 6px 16px;
} */

.index-buttons {
  border-radius: 50px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 10px;
  background-color: white;
}

.index-buttons:hover {
  cursor: pointer;
  transform: scale(1.05);
}

